import React, {Component} from 'react';

class SequrityPrice extends Component {
    constructor(){
        super();
        this.state= {
            list: [
                {
                    id: 1,
                    adTitle: "QR Codes",
                    text: '2',
                    text2: '2',
                    text3: '20',
                    text4: '250',
                    text5: '500',
                    text6: '1000',
                },
                {
                    id: 2,
                    adTitle: "Landing Pages",
                    text: '1',
                    text2: '2',
                    text3: '5',
                    text4: '25',
                    text5: '250',
                    text6: '500',
                },
                {
                    id: 3,
                    adTitle: "Users",
                    text: '1',
                    text2: '1',
                    text3: '2',
                    text4: '5',
                    text5: '10',
                    text6: '15',
                },
                {
                    id: 4,
                    adTitle: "VCards",
                    text: '1',
                    text2: '1',
                    text3: '5',
                    text4: '50',
                    text5: '100',
                    text6: '250',
                },
                {
                    id: 5,
                    adTitle: "Unlimited Scans",
                    icon: 'icon_close',
                    icon2: 'icon_check_alt2',
                    icon3: 'icon_check_alt2',
                    icon4: 'icon_check_alt2',
                    icon5: 'icon_check_alt2',
                    icon6: 'icon_check_alt2',
                },
                {
                    id: 6,
                    adTitle: "Dynamic (editable codes)",
                    icon: 'icon_check_alt2',
                    icon2: 'icon_check_alt2',
                    icon3: 'icon_check_alt2',
                    icon4: 'icon_check_alt2',
                    icon5: 'icon_check_alt2',
                    icon6: 'icon_check_alt2',
                },
                {
                    id: 7,
                    adTitle: "Custom Design",
                    icon: 'icon_check_alt2',
                    icon2: 'icon_check_alt2',
                    icon3: 'icon_check_alt2',
                    icon4: 'icon_check_alt2',
                    icon5: 'icon_check_alt2',
                    icon6: 'icon_check_alt2',
                },
                {
                    id: 8,
                    adTitle: "Analytics",
                    icon: 'icon_close',
                    icon2: 'icon_check_alt2',
                    icon3: 'icon_check_alt2',
                    icon4: 'icon_check_alt2',
                    icon5: 'icon_check_alt2',
                    icon6: 'icon_check_alt2',
                },
                {
                    id: 9,
                    adTitle: "Export Analytics",
                    icon: 'icon_close',
                    icon2: 'icon_check_alt2',
                    icon3: 'icon_check_alt2',
                    icon4: 'icon_check_alt2',
                    icon5: 'icon_check_alt2',
                    icon6: 'icon_check_alt2',
                },
                {
                    id: 10,
                    adTitle: "Export/Print (SVG, PNG, JPG, PDF)",
                    icon: 'icon_check_alt2',
                    icon2: 'icon_check_alt2',
                    icon3: 'icon_check_alt2',
                    icon4: 'icon_check_alt2',
                    icon5: 'icon_check_alt2',
                    icon6: 'icon_check_alt2',
                },
                {
                    id: 11,
                    adTitle: "Email Support",
                    icon: 'icon_close',
                    icon2: 'icon_check_alt2',
                    icon3: 'icon_check_alt2',
                    icon4: 'icon_check_alt2',
                    icon5: 'icon_check_alt2',
                    icon6: 'icon_check_alt2',
                },
                {
                    id: 12,
                    adTitle: "Phone Support",
                    icon: 'icon_close',
                    icon2: 'icon_check_alt2',
                    icon3: 'icon_check_alt2',
                    icon4: 'icon_check_alt2',
                    icon5: 'icon_check_alt2',
                    icon6: 'icon_check_alt2',
                },
                {
                    id: 13,
                    adTitle: "Account Manager",
                    icon: 'icon_close',
                    icon2: 'icon_close',
                    icon3: 'icon_close',
                    icon4: 'icon_close',
                    icon5: 'icon_check_alt2',
                    icon6: 'icon_check_alt2',
                },
            ]
        }
    }
    render(){
        return(
            <section className="pricing_area_four sec_pad">
            <div className="container">
                <div className="price_info_two price_info_three">
                    <div className="price_head">
                        <div className="p_head time">
                            <h4></h4>
                        </div>
                        <div className="p_head">
                            <h5>Free</h5>
                            <p>7 day free trial</p>
                        </div>
                        <div className="p_head">
                            <h5>Landing Page</h5>
                            <p>$99/yr</p>
                        </div>
                        <div className="p_head">
                            <h5>Zayo Pro</h5>
                            <p>$299/yr</p>
                        </div>
                        <div className="p_head">
                            <h5>Zayo Premium</h5>
                            <p>$499/yr</p>
                        </div>
                        <div className="p_head">
                            <h5>Platinum</h5>
                            <p>$999/yr</p>
                        </div>
                        <div className="p_head">
                            <h5>Platinum Plus</h5>
                            <p>$1899/yr</p>
                        </div>
                    </div>
                    <div className="price_body">
                        {
                            this.state.list.map( list => {
                                return(
                                    <div className="pr_list" key={list.id}>
                                        <div className="price_item">
                                        <h5 className="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Save time by using keyboard shortcuts all across SaasLand">{list.adTitle}</h5>
                                        </div>
                                        <div className="price_item" data-title="Free">
                                            {
                                                list.text?<h5>{list.text}</h5>:<h5 className="check"><i className={list.icon}></i></h5>
                                            }
                                        </div>
                                        <div className="price_item" data-title="Landing">
                                            {
                                                list.text2?<h5>{list.text2}</h5>:<h5 className="check"><i className={list.icon2}></i></h5>
                                            }
                                        </div>
                                        <div className="price_item" data-title="Pro">
                                            {
                                                list.text3?<h5>{list.text3}</h5>:<h5 className="check"><i className={list.icon3}></i></h5>
                                            }
                                        </div>
                                        <div className="price_item" data-title="Premium">
                                            {
                                                list.text4?<h5>{list.text4}</h5>:<h5 className="check"><i className={list.icon4}></i></h5>
                                            }
                                        </div>
                                        <div className="price_item" data-title="Platinum">
                                            {
                                                list.text5?<h5>{list.text5}</h5>:<h5 className="check"><i className={list.icon5}></i></h5>
                                            }
                                        </div>
                                        <div className="price_item" data-title="Platinum-Plus">
                                            {
                                                list.text6?<h5>{list.text6}</h5>:<h5 className="check"><i className={list.icon6}></i></h5>
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {/* <div className="pr_list">
                            <div className="price_item">
                            </div>
                            <div className="price_item">
                                <a href="/#" className="price_btn btn_hover">Choose This</a>
                            </div>
                            <div className="price_item">
                                <a href="/#" className="price_btn btn_hover">Choose This</a>
                            </div>
                            <div className="price_item">
                                <a href="/#" className="price_btn btn_hover">Choose This</a>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
        )
    }
}
export default SequrityPrice;