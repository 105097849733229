import React, {Component} from 'react';
import Reveal from 'react-reveal/Reveal';
class Sservice extends Component{
    render(){
        return(
            <section className="Term-section-1 bg_color">
                <div className="container">
                    <div className="card term-card">
                        <h5 className="sub-heading t_color3">1. About the Website</h5>
                        <ol className="terms">
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">Welcome to www.zayo.com.au (the '<b>Website</b>'). The Website provides you with anopportunity to browse and purchase various products that have been listed for sale through the Website (the '<b>Products</b> '). The Website provides this service by way of granting you access to the content on the Website (the '<b>PurchaseServices</b>').</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">The Website is operated by SIO Direct Pty Ltd. Access to and use of the Website, or any of its associated Products or Services, is provided by SIO Direct Pty Ltd. Please read these terms and conditions (the '<b>Terms</b>') carefully. By using, browsing and/or reading the Website, this signifies that you have read, understood and agree to be bound by the Terms. If you do not agree with the Terms, you must cease usage of the Website, or any of Services, immediately.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">SIO Direct Pty Ltd reserves the right to review and change any of the Terms by updating this page at its sole discretion. When SIO Direct Pty Ltd updates the Terms, it will use reasonable endeavours to provide you with notice of updates to the Terms. Any changes to the Terms take immediate effect from the date of theirpublication. Before you continue, we recommend you keep a copy of the Terms for your records.</p>
                            </li>
                        </ol>
                    </div>
                    <div className="card term-card">
                        <h5 className="sub-heading t_color3">2. Acceptance of the Terms</h5>
                        <ol className="terms">
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">You accept the Terms by remaining on the Website. You may also acceptthe Terms by clicking to accept or agree to the Terms where this option ismade available to you by SIO Direct Pty Ltd in the user interface.</p>
                            </li>
                        </ol>
                    </div>
                    <div className="card term-card">
                        <h5 className="sub-heading t_color3">3. Registration to use the Purchase Services</h5>
                        <ol className="terms">
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">In order to access the Purchase Services, you must first register as a user of the Website. As part of the registration process, or as part of your continued use of the Purchase Services, you may be required to provide personal information about yourself (such as identification or contact details), including:</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">You warrant that any information you give to SIO Direct Pty Ltd in the course of completing the registration process will always be accurate, correct and up to date.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">Once you have completed the registration process, you will be a registered member of the Website ('<b>Member</b>') and agree to be bound by the Terms. As a Member you will be granted immediate access to the Purchase Services.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">You may not use the Purchase Services and may not accept the Terms if:</p>
                                <ol className="inner-ul">
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">you are not of legal age to form a binding contract with SIO Direct Pty Ltd; or</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">you are a person barred from receiving the Purchase Services under thelaws of Australia or other countries including the country in which youare resident or from which you use the Purchase Services.</p>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                    <div className="card term-card">
                        <h5 className="sub-heading t_color3">4. Your obligations as a Member</h5>
                        <ol className="terms">
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">As a Member, you agree to comply with the following: You will use the Purchase Services only for purposes that are permitted by:</p>
                                <ol className="inner-ul">
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">the Terms;</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">any applicable law, regulation or generally accepted practices or guidelines in the relevant jurisdictions;</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">you have the sole responsibility for protecting the confidentiality of your password and/or email address. Use of your password by any other person may result in the immediate cancellation of the Purchase Services;</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">any use of your registration information by any other person, or third parties, is strictly prohibited. You agree to immediately notify SIO Direct Pty Ltd of any unauthorised use of your password or email address or anybreach of security of which you have become aware;</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">access and use of the Website is limited, non-transferable and allows forthe sole use of the Website by you for the purposes of SIO Direct PtyLtd providing the Purchase Services;</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">you will not use the Purchase Services or Website for any illegal and/or unauthorised use which includes collecting email addresses of Members by electronic or other means for the purpose of sending unsolicited email or unauthorised framing of or linking to the Website;</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">you agree that commercial advertisements, affiliate links, and other forms of solicitation may be removed from the Website without notice and may result in termination of the Purchase Services. Appropriate legal action will be taken by SIO Direct Pty Ltd for any illegal or unauthorised use of the Website; and</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">you acknowledge and agree that any automated use of the Website or its Purchase Services is prohibited.</p>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                    <div className="card term-card">
                        <h5 className="sub-heading t_color3">5. Purchase of Products and Returns Policy</h5>
                        <ol className="terms">
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">n using the Purchase Services to purchase the Product through the Website, you will agree to the payment of the purchase price listed on the Website for the Product (the '<b>Purchase Price</b>').</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">Payment of the Purchase Price may be made through Stripe (the '<b>PaymentGateway Provider</b>'). In using the Purchase Services, you warrant that you havefamiliarised yourself with, and agree to be bound by, the applicable Terms and Conditions of Use, Privacy Policy and other relevant legal documentation providedby the Payment Gateway Providers.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">Following payment of the Purchase Price being confirmed by SIO Direct Pty Ltd, you will be issued with a receipt to confirm that the payment has been received and SIO Direct Pty Ltd may record your purchase details for future use.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">SIO Direct Pty Ltd may, at their sole discretion, provide a refund on the return of the Products within 90 days days where the Product packaging is unopened and remains in a saleable condition. You acknowledge and agree that you are liable for any postage and shipping costs associated with any refund pursuant to this clause.</p>
                            </li>
                        </ol>
                    </div>
                    <div className="card term-card">
                        <h5 className="sub-heading t_color3">6. Warranty</h5>
                        <ol className="terms">
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">SIO Direct Pty Ltd's Products come with guarantees that cannot be excluded under the Australian Consumer Law. You are entitled to a replacement or refund for a major failure of the Product and compensation for any other reasonably foreseeable loss or damage. You are also entitled to have the Products repaired or replaced if the Products fail to be of acceptable quality and the failure does not amount to a major failure (the '<b>Warranty</b>').</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">You may make a claim under this clause (the 'Warranty Claim') for material defects and workmanship in the Products within 90 days from the date of purchase (the '<b>Warranty Period</b>').</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">In order to make a Warranty Claim during the Warranty Period, you must provide proof of purchase to SIO Direct Pty Ltd showing the date of purchase of the Products, provide a description of the Products and the price paid for the Products by sending written notice to SIO Direct Pty Ltd at ...... or by email at <a href="mailto:help@zayo.com.au">help@zayo.com.au</a>.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">Where the Warranty Claim is accepted then SIO Direct Pty Ltd will, at its sole discretion, either repair or replace any defective Products or part thereof with a new or remanufactured equivalent during the Warranty Period at no charge to youfor parts or labour. You acknowledge and agree that you will be solely liable for any postage or shipping costs incurred in facilitating the Warranty Claim.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">The Warranty shall be the sole and exclusive warranty granted by SIO Direct Pty Ltd and shall be the sole and exclusive remedy available to you in addition to other rightsand under a law in relation to the Products to which this warranty relates.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">All implied warranties including the warranties of merchantability and fitness for use are limited to the Warranty Period.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">The Warranty does not apply to any appearance of the supplied Products nor to the additional excluded items set forth below nor to any supplied Products wherethe exterior of which has been damaged or defaced, which has been subjected to misuse, abnormal service or handling, or which has been altered or modified in design or construction.</p>
                            </li>
                        </ol>
                    </div>
                    <div className="card term-card">
                        <h5 className="sub-heading t_color3">7. Copyright and Intellectual Property</h5>
                        <ol className="terms">
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">The Website, the Purchase Services and all of the related products of SIO Direct Pty Ltd are subject to copyright. The material on the Website is protected by copyright under the laws of Australia and through international treaties. Unless otherwise indicated, all rights (including copyright) in the site content andcompilation of the website (including text, graphics, logos, button icons, video images, audio clips and software) (the '<b>Content</b>') are owned or controlled for these purposes, and are reserved by SIO Direct Pty Ltd or its contributors.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">SIO Direct Pty Ltd retains all rights, title and interest in and to the Website and all related content. Nothing you do on or in relation to the Website will transfer to you:</p>
                                <ol className="inner-ul">
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">the business name, trading name, domain name, trade mark, industrialdesign, patent, registered design or copyright of SIO Direct Pty Ltd; or</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">the right to use or exploit a business name, trading name, domain name, trade mark or industrial design; or</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">a system or process that is the subject of a patent, registered design or copyright (or an adaptation or modification of such a system or process).</p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">You may not, without the prior written permission of SIO Direct Pty Ltd and the permission of any other relevant rights owners: broadcast, republish, up-load to athird party, transmit, post, distribute, show or play in public, adapt or change in any way the Content or third party content for any purpose. This prohibition does not extend to materials on the Website, which are freely available for re-use or are in the public domain.</p>
                            </li>
                        </ol>
                    </div>
                    <div className="card term-card">
                        <h5 className="sub-heading t_color3">8. Privacy</h5>
                        <ol className="terms">
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">SIO Direct Pty Ltd takes your privacy seriously and any information provided through your use of the Website and/or the Purchase Services are subject to SIO Direct Pty Ltd's Privacy Policy, which is available on the Website.</p>
                            </li>
                        </ol>
                    </div>
                    <div className="card term-card">
                        <h5 className="sub-heading t_color3">9. General Disclaimer</h5>
                        <ol className="terms">
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">You acknowledge that SIO Direct Pty Ltd does not make any terms, guarantees, warranties, representations or conditions whatsoever regarding the Products otherthan provided for pursuant to these Terms.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">SIO Direct Pty Ltd will make every effort to ensure a Product is accurately depicted on the Website, however, you acknowledge that sizes, colours and packaging may differ from what is displayed on the Website.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">Nothing in these Terms limits or excludes any guarantees, warranties, representations or conditions implied or imposed by law, including the Australian Consumer Law (or any liability under them) which by law may not be limited or excluded.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">Subject to this clause, and to the extent permitted by law:</p>
                                <ol className="inner-ul">
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">all terms, guarantees, warranties, representations or conditions which are not expressly stated in these Terms are excluded; and</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">SIO Direct Pty Ltd will not be liable for any special, indirect or consequential loss or damage (unless such loss or damage is reasonably foreseeable resulting from our failure to meet an applicable Consumer Guarantee), loss of profit or opportunity, or damage to goodwill arising out of or in connection with the Purchase Services or these Terms (including as a result of not being able to use the Purchase Services or the late supply of the Purchase Services), whether at common law, under contract,tort (including negligence), in equity, pursuant to statute or otherwise.</p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">Use of the Website, the Purchase Services, and any of the products of SIO DirectPty Ltd , is at your own risk. Everything on the Website, the Purchase Services, and the Products of SIO Direct Pty Ltd, are provided to you on an "as is" and "as available" basis, without warranty or condition of any kind. None of the affiliates, directors, officers, employees, agents, contributors, third party content providers or licensors of SIO Direct Pty Ltd make any express or implied representation or warranty about its Content or any products or Purchase Services (including the products or Purchase Services of SIO Direct Pty Ltd) referred to on the Website. This includes (but is not restricted to) loss or damage you might suffer as a result of any of the following:</p>
                                <ol className="inner-ul">
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">failure of performance, error, omission, interruption, deletion, defect, failure to correct defects, delay in operation or transmission, computer virus or other harmful component, loss of data, communication line failure, unlawful third party conduct, or theft, destruction, alteration or unauthorised access to records;</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">the accuracy, suitability or currency of any information on the Website, the Purchase Service, or any of its Content related products (including third party material and advertisements on the Website);</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">costs incurred as a result of you using the Website, the Purchase Services or any of the Products;</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">the Content or operation in respect to links which are provided for the User's convenience;</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">any failure to complete a transaction, or any loss arising from e-commerce transacted on the Website; or</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">any defamatory, threatening, offensive or unlawful conduct of third parties or publication of any materials relating to or constituting such conduct.</p>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                    <div className="card term-card">
                        <h5 className="sub-heading t_color3">10. Limitation of Liability</h5>
                        <ol className="terms">
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">SIO Direct Pty Ltd's total liability arising out of or in connection with the Purchase Services or these Terms, however arising, including under contract, tort (including negligence), in equity, under statute or otherwise, will not exceed the most recent Purchase Price paid by you under these Terms or where you have not paid the Purchase Price, then the total liability of SIO Direct Pty Ltd is the resupply of information or Purchase Services to you.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">You expressly understand and agree that SIO Direct Pty Ltd, its affiliates, employees, agents, contributors, third party content providers and licensors shall not be liable to you for any direct, indirect, incidental, special consequential or exemplary damages which may be incurred by you, however caused and under any theory of liability. This shall include, but is not limited to, any loss of profit (whether incurred directly or indirectly), any loss of goodwill or business reputation and any other intangible loss.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">SIO Direct Pty Ltd is not responsible or liable in any manner for any site content (including the Content and Third Party Content) posted on the Website or in connection with the Purchase Services, whether posted or caused by users of thewebsite of SIO Direct Pty Ltd, by third parties or by any of the Purchase Services offered by SIO Direct Pty Ltd.</p>
                            </li>
                        </ol>
                    </div>
                    <div className="card term-card">
                        <h5 className="sub-heading t_color3">11. Termination of Contract</h5>
                        <ol className="terms">
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">The Terms will continue to apply until terminated by either you or by SIO Direct Pty Ltd as set out below.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">If you want to terminate the Terms, you may do so by:</p>
                                <ol className="inner-ul">
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">notifying SIO Direct Pty Ltd at any time; and</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">closing your accounts for all of the Purchase Services which you use,where SIO Direct Pty Ltd has made this option available to you.</p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">Your notice should be sent, in writing, to SIO Direct Pty Ltd via the 'Contact Us' link on our homepage.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">SIO Direct Pty Ltd may at any time, terminate the Terms with you if:</p>
                                <ol className="inner-ul">
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">you have breached any provision of the Terms or intend to breach any provision;</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">SIO Direct Pty Ltd is required to do so by law;</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">the partner with whom SIO Direct Pty Ltd offered the Purchase Services to you has terminated its relationship with SIO Direct Pty Ltd or ceased to offer the Purchase Services to you;</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">SIO Direct Pty Ltd is transitioning to no longer providing the Purchase Services to Users in the country in which you are resident or from whichyou use the service; or</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">the provision of the Purchase Services to you by SIO Direct Pty Ltd is, inthe opinion of SIO Direct Pty Ltd, no longer commercially viable.</p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">Subject to local applicable laws, SIO Direct Pty Ltd reserves the right to discontinue or cancel your membership to the Website at any time and may suspend or deny, in its sole discretion, your access to all or any portion of the Website or the Purchase Services without notice if you breach any provision of the Terms or any applicable law or if your conduct impacts SIO Direct Pty Ltd's name or reputation or violates the rights of those of another party.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">When the Terms come to an end, all of the legal rights, obligations and liabilities that you and SIO Direct Pty Ltd have benefited from, been subject to (or which have accrued over time whilst the Terms have been in force) or which are expressed to continue indefinitely, shall be unaffected by this cessation, and the provisions of this clause shall continue to apply to such rights, obligations and liabilities indefinitely.</p>
                            </li>
                        </ol>
                    </div>
                    <div className="card term-card">
                        <h5 className="sub-heading t_color3">12. Indemnity</h5>
                        <ol className="terms">
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">You agree to indemnify SIO Direct Pty Ltd, its affiliates, employees, agents, contributors, third party content providers and licensors from and against:</p>
                                <ol className="inner-ul">
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">all actions, suits, claims, demands, liabilities, costs, expenses, loss and damage (including legal fees on a full indemnity basis) incurred, suffered or arising out of or in connection with any Content you post through the Website;</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">any direct or indirect consequences of you accessing, using or transactingon the Website or attempts to do so and any breach by you or your agentsof these Terms; and/or</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">any breach of the Terms.</p>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                    <div className="card term-card">
                        <h5 className="sub-heading t_color3">13. Dispute Resolution</h5>
                        <ol className="terms">
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">Compulsory:</p>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">If a dispute arises out of or relates to the Terms, either party may not commence any Tribunal or Court proceedings in relation to the dispute, unless the following clauses have been complied with (except where urgent interlocutory relief is sought).</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">Notice:</p>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">A party to the Terms claiming a dispute ('<b>Dispute</b>') has arisen under theTerms, must give written notice to the other party detailing the nature of thedispute, the desired outcome and the action required to settle the Dispute.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">Resolution:</p>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">On receipt of that notice ('<b>Notice</b>') by that other party, the parties to the Terms('<b>Parties</b>') must:</p>
                                <ol className="inner-ul">
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">Within 30 days of the Notice endeavour in good faith to resolve the Dispute expeditiously by negotiation or such other means upon which they may mutually agree;</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">If for any reason whatsoever, 14 days after the date of the Notice, the Dispute has not been resolved, the Parties must either agree upon selection of a mediator or request that an appropriate mediator be appointed by the President of the Australian Mediation Service or his or her nominee;</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">The Parties are equally liable for the fees and reasonable expenses of a mediator and the cost of the venue of the mediation and without limiting the foregoing undertake to pay any amounts requested by the mediator as a pre-condition to the mediation commencing. The Parties must each pay their own costs associated with the mediation;</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">The mediation will be held in Melbourne, Australia.</p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">Confidential:</p>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">All communications concerning negotiations made by the Parties arising out of and in connection with this dispute resolution clause are confidential and to the extent possible, must be treated as "without prejudice" negotiations for the purpose of applicable laws of evidence.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">Termination of Mediation:</p>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">If 3 months have elapsed after the start of a mediation of the Dispute and the Dispute has not been resolved, either Party may ask the mediator to terminate the mediation and the mediator must do so.</p>
                            </li>
                        </ol>
                    </div>
                    <div className="card term-card">
                        <h5 className="sub-heading t_color3">14. Venue and Jurisdiction</h5>
                        <ol className="terms">
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">The Purchase Services offered by SIO Direct Pty Ltd is intended to be viewed by residents of Australia. In the event of any dispute arising out of or in relation to the Website, you agree that the exclusive venue for resolving any dispute shall be in the courts of New South Wales, Australia.</p>
                            </li>
                        </ol>
                    </div>
                    <div className="card term-card">
                        <h5 className="sub-heading t_color3">15. Governing Law</h5>
                        <ol className="terms">
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">The Terms are governed by the laws of New South Wales, Australia. Any dispute, controversy, proceeding or claim of whatever nature arising out of or in any way relating to the Terms and the rights created hereby shall be governed, interpreted and construed by, under and pursuant to the laws of New South Wales Australia, without reference to conflict of law principles, notwithstanding mandatory rules. The validity of this governing law clause is not contested. The Terms shall be binding to the benefit of the parties hereto and their successors and assigns.</p>
                            </li>
                        </ol>
                    </div>
                    <div className="card term-card">
                        <h5 className="sub-heading t_color3">16. Independent Legal Advice</h5>
                        <ol className="terms">
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">Both parties confirm and declare that the provisions of the Terms are fair and reasonable and both parties having taken the opportunity to obtain independent legal advice and declare the Terms are not against public policy on the grounds of inequality or bargaining power or general grounds of restraint of trade.</p>
                            </li>
                        </ol>
                    </div>
                    <div className="card term-card">
                        <h5 className="sub-heading t_color3">17. Severance</h5>
                        <ol className="terms">
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">If any part of these Terms is found to be void or unenforceable by a Court of competent jurisdiction, that part shall be severed and the rest of the Terms shall remain in force.</p>
                            </li>
                        </ol>
                    </div>
                    
                </div>
            </section>
        )
    }
}
export default Sservice;