import React, {Component} from 'react';
import Reveal from 'react-reveal/Reveal';
class Sservice extends Component{
    render(){
        return(
            <section className="Term-section-1 bg_color">
                <div className="container">
                    <div className="card term-card">
                        <h5 className="sub-heading t_color3">1. We respect your privacy</h5>
                        <ol className="terms">
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">SIO Direct Pty Ltd respects your right to privacy and is committed to safeguarding the privacy of our customers and website visitors. This policy setsout how we collect and treat your personal information.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">We adhere to the Australian Privacy Principles contained in the Privacy Act1988 (Cth) and to the extent applicable, the EU General Data Protection Regulation (GDPR).</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">"Personal information" is information we hold which is identifiable as being about you. This includes information such as your name, email address, identification number, or any other type of information that can reasonably identify an individual, either directly or indirectly.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">You may contact us in writing at 63 Summer Dr, Buronga, New South Wales, 2739 for further information about this Privacy Policy.</p>
                            </li>
                        </ol>
                    </div>
                    <div className="card term-card">
                        <h5 className="sub-heading t_color3">2. What personal information is collected</h5>
                        <ol className="terms">
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">SIO Direct Pty Ltd will, from time to time, receive and store personal information you submit to our website, provided to us directly or given to us in other forms.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">You may provide basic information such as your name, phone number, address and email address to enable us to send you information, provide updates and process your product or service order.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">We may collect additional information at other times, including but not limited to, when you provide feedback, when you provide information about your personal or business affairs, change your content or email preference, respond to surveys and/or promotions, provide financial or credit card information, or communicate with our customer support.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">Additionally, we may also collect any other information you provide while interacting with us.</p>
                            </li>
                        </ol>
                    </div>
                    <div className="card term-card">
                        <h5 className="sub-heading t_color3">3. How we collect your personal information</h5>
                        <ol className="terms">
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">SIO Direct Pty Ltd collects personal information from you in a variety of ways, including when you interact with us electronically or in person, when you access our website and when we engage in business activities with you. We may receive personal information from third parties. If we do, we will protect it as set out in this Privacy Policy.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">By providing us with personal information, you consent to the supply of that information subject to the terms of this Privacy Policy.</p>
                            </li>
                        </ol>
                    </div>
                    <div className="card term-card">
                        <h5 className="sub-heading t_color3">4. How we use your personal information</h5>
                        <ol className="terms">
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">SIO Direct Pty Ltd may use personal information collected from you to provide you with information about our products or services. We may also make you aware of new and additional products, services and opportunities available to you.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">SIO Direct Pty Ltd will use personal information only for the purposes that you consent to. This may include to:</p>
                                <ol className="inner-ul">
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">provide you with products and services during the usual course of ourbusiness activities;</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">administer our business activities;</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">manage, research and develop our products and services;</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">provide you with information about our products and services;</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">communicate with you by a variety of measures including, but not limited to, by telephone, email, sms or mail; and</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">investigate any complaints.</p>
                                    </li>
                                </ol>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">If you withhold your personal information, it may not be possible for us to provide you with our products and services or for you to fully access our website.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">We may disclose your personal information to comply with a legal requirement, such as a law, regulation, court order, subpoena, warrant, legal proceedings or in response to a law enforcement agency request.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">If there is a change of control in our business or a sale or transfer of business assets, we reserve the right to transfer to the extent permissible at law our userdatabases, together with any personal information and non-personal information contained in those databases.</p>
                            </li>
                        </ol>
                    </div>
                    <div className="card term-card">
                        <h5 className="sub-heading t_color3">5. Disclosure of your personal information</h5>
                        <ol className="terms">
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">SIO Direct Pty Ltd may disclose your personal information to any of our employees, officers, insurers, professional advisers, agents, suppliers or subcontractors insofar as reasonably necessary for the purposes set out in thisprivacy policy.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">If we do disclose your personal information to a third party, we will protect it in accordance with this privacy policy.</p>
                            </li>
                        </ol>
                    </div>
                    <div className="card term-card">
                        <h5 className="sub-heading t_color3">6. General Data Protection Regulation (GDPR) for the European Union (EU)</h5>
                        <ol className="terms">
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">SIO Direct Pty Ltd will comply with the principles of data protection set out in the GDPR for the purpose of fairness, transparency and lawful data collection and use.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">We process your personal information as a Processor and/or to the extent thatwe are a Controller as defined in the GDPR.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">We   must   establish   a   lawful   basis   for   processing   your   personal   information.The legal basis for which we collect your personal information depends on thedata that we collect and how we use it.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">We will only collect your personal information with your express consent for a specific purpose and any data collected will be to the extent necessary and not excessive for its purpose. We will keep your data safe and secure.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">We will also process your personal information if it is necessary for our legitimate interests, or to fulfil a contractual or legal obligation.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">We process your personal information if it is necessary to protect your life or in a medical situation, it is necessary to carry out a public function, a task of public interest or if the function has a clear basis in law.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">We do not collect or process any personal information from you that is considered "Sensitive Personal Information" under the GDPR, such as personal information relating to your sexual orientation or ethnic origin unless we have obtained your explicit consent, or if it is being collected subject to and in accordance with the GDPR.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">You must not provide us with your personal information if you are under the age of 16 without the consent of your parent or someone who has parental authority for you. We do not knowingly collect or process the personal information of children.</p>
                            </li>
                        </ol>
                    </div>
                    <div className="card term-card">
                        <h5 className="sub-heading t_color3">7. Your rights under the GDPR</h5>
                        <ol className="terms">
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">If you are an individual residing in the EU, you have certain rights as to how your personal information is obtained and used. SIO Direct Pty Ltd complies with your rights under the GDPR as to how your personal information is used and controlled if you are an individual residing in the EU.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">Except as otherwise provided in the GDPR, you have the following rights:</p>

                                <ol className="inner-ul">
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">to be informed how your personal information is being used;</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">access your personal information (we will provide you with a free copy of it);</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">to correct your personal information if it is inaccurate or incomplete;</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">to delete your personal information (also known as "the right to be forgotten");</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">to restrict processing of your personal information;</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">to retain and reuse your personal information for your own purposes;</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">to object to your personal information being used; and</p>
                                    </li>
                                    <li>
                                        <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">to object against automated decision making and profiling.</p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">Please contact us at any time to exercise your rights under the GDPR at the contact details in this Privacy Policy.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">We may ask you to verify your identity before acting on any of your requests.</p>
                            </li>
                        </ol>
                    </div>
                    <div className="card term-card">
                        <h5 className="sub-heading t_color3">8. Hosting and International Data Transfers</h5>
                        <ol className="terms">
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">Information that we collect may from time to time be stored, processed in ortransferred   between   parties   or   sites   located   in   countries   outside   ofAustralia. These may include, but are not limited to Australia and USA.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">We and our other group companies have offices and/or facilities in Australia andUSA. Transfers to each of these countries will be protected by appropriate safeguards, these include one or more of the following: the use of standard dataprotection clauses adopted or approved by the European Commission which you can obtain from the European Commission Website; the use of binding corporate rules, a copy of which you can obtain from SIO Direct Pty Ltd's Data Protection Officer.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">The hosting facilities for our website are situated in Australia and USA. Transfers toeach of these Countries will be protected by appropriate safeguards, these include one or more of the following: the use of standard data protection clauses adopted or approved by the European Commission which you can obtain from the EuropeanCommission Website; the use of binding corporate rules, a copy of which you can obtain from SIO Direct Pty Ltd's Data Protection Officer.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">Our Suppliers and Contractors are situated in Australia and USA. Transfers to eachof these Countries will be protected by appropriate safeguards, these include one or more of the following: the use of standard data protection clauses adopted or approved by the European Commission which you can obtain from the European Commission Website; the use of binding corporate rules, a copy of which you can obtain from SIO Direct Pty Ltd's Data Protection Officer.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">You acknowledge that personal data that you submit for publication through our website or services may be available, via the internet, around the world. We cannot prevent the use (or misuse) of such personal data by others.</p>
                            </li>
                        </ol>
                    </div>
                    <div className="card term-card">
                        <h5 className="sub-heading t_color3">9. Security of your personal information</h5>
                        <ol className="terms">
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">SIO Direct Pty Ltd is committed to ensuring that the information you provide to us is secure. In order to prevent unauthorised access or disclosure, we have putin place suitable physical, electronic and managerial procedures to safeguard and secure information and protect it from misuse, interference, loss and unauthorised access, modification and disclosure.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">Where we employ data processors to process personal information on our behalf, we only do so on the basis that such data processors comply with the requirements under the GDPR and that have adequate technical measures in place to protect personal information against unauthorised use, loss and theft.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">The transmission and exchange of information is carried out at your own risk. We cannot guarantee the security of any information that you transmit to us, or receive from us. Although we take measures to safeguard against unauthoriseddisclosures of information, we cannot assure you that personal information that we collect will not be disclosed in a manner that is inconsistent with this PrivacyPolicy.</p>
                            </li>
                        </ol>
                    </div>
                    <div className="card term-card">
                        <h5 className="sub-heading t_color3">10. Access to your personal information</h5>
                        <ol className="terms">
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">You may request details of personal information that we hold about you in accordance with the provisions of the Privacy Act 1988 (Cth), and to the extentapplicable the EU GDPR. If you would like a copy of the information which we hold about you or believe that any information we hold on you is inaccurate, out of date, incomplete, irrelevant or misleading, please email us at <a href="mailto:privacy@zayo.com.au">privacy@zayo.com.au</a>.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">We reserve the right to refuse to provide you with information that we hold about you, in certain circumstances set out in the Privacy Act or any other applicable law.</p>
                            </li>
                        </ol>
                    </div>
                    <div className="card term-card">
                        <h5 className="sub-heading t_color3">11. Complaints about privacy</h5>
                        <ol className="terms">
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">If you have any complaints about our privacy practices, please feel free to send in details of your complaints to privacy@zayo.com.au. We take complaints very seriously and will respond shortly after receiving written notice of your complaint.</p>
                            </li>
                        </ol>
                    </div>
                    <div className="card term-card">
                        <h5 className="sub-heading t_color3">12. Changes to Privacy Policy</h5>
                        <ol className="terms">
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">Please be aware that we may change this Privacy Policy in the future. We may modify this Policy at any time, in our sole discretion and all modifications will be effective immediately upon our posting of the modifications on our website or notice board. Please check back from time to time to review our Privacy Policy.</p>
                            </li>
                        </ol>
                    </div>
                    <div className="card term-card">
                        <h5 className="sub-heading t_color3">13. Website</h5>
                        <ol className="terms">
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">When you visit our websiteWhen you come to our website (<a href="www.zayo.com.au">www.zayo.com.au</a> ), we may collect certain information   such   as   browser   type,   operating   system,   website   visited   immediatelybefore coming to our site, etc. This information is used in an aggregated manner toanalyse how people use our site, such that we can improve our service</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">Cookies</p>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">We may from time to time use cookies on our website. Cookies are very small files which a website uses to identify you when you come back to the site and to store details about your use of the site. Cookies are not malicious programs that access or damage your computer. Most web browsers automatically accept cookies but you can choose to reject cookies by changing your browser settings. However, this may prevent you from taking full advantage of our website. Our website may from time to time use cookies to analyses website traffic and help us provide a better website visitor experience. In addition, cookies may be used to serve relevant ads to website visitors through third party services such as Google AdWords. These ads may appear on this website or other websites you visit.</p>
                            </li>
                            <li>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">Third party sites</p>
                                <p class="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">Our site may from time to time have links to other websites not owned or controlled by us. These links are meant for your convenience only. Links to thirdparty websites do not constitute sponsorship or endorsement or approval of these websites. Please be aware that SIO Direct Pty Ltd is not responsible for the privacy practises of other such websites. We encourage our users to be aware, when they leave our website, to read the privacy statements of each and every website that collects personal identifiable information.</p>
                            </li>
                        </ol>
                    </div>
                </div>
            </section>
        )
    }
}
export default Sservice;