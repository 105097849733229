import React, { Component } from 'react'
import Slider from 'react-slick'
import Activecampaign from '../activecampaign/activecampaign'
class AgencyAbout extends Component {
  render () {
    const settings = {
      dots: true,
      infinite: true,
      speed: 1500,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 4000,
      slidesToShow: 1,
      slidesToScroll: 1
    }
    let ServiceData = this.props.ServiceData
    return (
      <section className='agency_about_area d-flex bg_color'>
        <div className='col-lg-6 col-md-4 about_content_left '>
          <div className='about_content mb_30'>
            <h2 className='f_size_30 f_700 l_height45 mb_20'>
              Looking for shortcuts and easy hacks to crush your marketing goals
              this year?
            </h2>
            <p className='f_size_15 f_400 mb_40'>
              If you are serious about marketing your product and making sales,
              then you should look into QR Codes to keep up with the times. Use
              Zayo QR codes for a quick response from your printed media and
              offline marketing to your online marketing channels, so your
              customers have easy access to your service, product or branding
              offers.
            </p>
            {/* <a
              href='https://zayo.com.au/admin-panel/business-user-signup'
              className='about_btn'
            >
              Sign up for free 7 day trial
            </a> */}
            <div className="agency_about_campaign">
              <Activecampaign />
            </div>
          </div>
        </div>
        <div className='col-lg-6 col-md-8 about_img'>
          {/* <img src={require('../../img/custom-images/slider-exchange-image.jpg')} className="img-fluid" alt=""/> */}
          {/* <a href=".#" className="pluse_icon"><i className="ti-plus"></i></a> */}
          <Slider className='about_img_slider' {...settings}>
            <div className='item'>
              <div
                className='about_item w45'
                style={{
                  backgroundImage: `url(${require('../../img/home4/team1.jpg')})`
                }}
              >
                {/* <img src={require('../../img/home4/team1.jpg')} alt=""/> */}
              </div>
              <div
                className='about_item w55'
                style={{
                  backgroundImage: `url(${require('../../img/home4/team2.jpg')})`
                }}
              >
                {/* <img src={require('../../img/home4/team2.jpg')} alt=""/> */}
              </div>
              <div
                className='about_item w55'
                style={{
                  backgroundImage: `url(${require('../../img/home4/team3.jpg')})`
                }}
              >
                {/* <img src={require('../../img/home4/team3.jpg')} alt=""/> */}
              </div>
            </div>
            <div className='item'>
              <div
                className='about_item w45'
                style={{
                  backgroundImage: `url(${require('../../img/home4/team4.jpg')})`
                }}
              >
                {/* <img src={require('../../img/home4/team4.jpg')} alt=""/> */}
              </div>
              <div
                className='about_item w55'
                style={{
                  backgroundImage: `url(${require('../../img/home4/team5.jpg')})`
                }}
              >
                {/* <img src={require('../../img/home4/team5.jpg')} alt=""/> */}
              </div>
              <div
                className='about_item w55'
                style={{
                  backgroundImage: `url(${require('../../img/home4/team6.jpg')})`
                }}
              >
                {/* <img src={require('../../img/home4/team6.jpg')} alt=""/> */}
              </div>
            </div>
          </Slider>
        </div>
      </section>
    )
  }
}
export default AgencyAbout
