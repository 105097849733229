import React, { Component } from 'react'
import Activecampaign from './activecampaign/activecampaign'

class AgencyAction extends Component {
  render () {
    return (
      <section className='action_area_three sec_pad'>
        <div className='curved'></div>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='action_content text-center'>
                <h2 className='f_600 f_size_30 l_height45 mb_40'>
                  {/* Ready to enjoy Zayo and expenses? */}
                </h2>
                {/* <a href=".#" className="about_btn white_btn wow fadeInLeft" data-wow-delay="0.3s">Just SaasLand IT</a> */}
                {/* <a
                  href='https://zayo.com.au/admin-panel/business-user-signup'
                  className='about_btn wow fadeInRight'
                  data-wow-delay='0.4s'
                >
                  Sign up for a free 7 day trial
                </a> */}
                <div className="agency_action">
                  <Activecampaign />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default AgencyAction
