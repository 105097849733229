import React, { Fragment } from "react";
import Breadcrumb from "../components/Breadcrumb";
import CustomNavbar from "../components/CustomNavbar";
import Reveal from "react-reveal/Reveal";
import { Link } from "react-router-dom";
import FooterTwo from "../components/Footer/FooterTwo";
import FooterData from "../components/Footer/FooterData";
import ReactPlayer from "react-player";
const Features = () => {
  return (
    <Fragment>
      <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto" />
      <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="QR Code Types" />
      <section className="features_section  sec_pad  bg_color ">
        <div className="container">
          <div className="features_info mb-0 pb-0">
            <div className={`agency_featured_item align-items-center row flex-row-reverse mt-0`} id="dynamicQrCode">
              <div className="col-lg-5 ">
                <Reveal effect="fadeInLeft">
                  <div className="agency_featured_img text-right">
                    <img src={require("../img/custom-images/qr_dynamic.jpg")} alt="" className="img-fluid" />
                  </div>
                </Reveal>
              </div>
              <div className="col-lg-7 ">
                <div className={`agency_featured_content wow fadeInLeft pr-lg-4`} data-wow-delay="0.6s">
                  <Reveal effect="fadeInRight">
                    <h3 className="mt-0">Dynamic QR code</h3>
                    <h5 className="mb-3">Instantly connect to any link</h5>
                    <p>
                      If you advertise, promote or print, you need to make it easy for people to connect with your
                      online message.
                    </p>
                    <p>
                      Whether it’s your website, a specific page on your website such as a contact form or landing page,
                      a Dropbox link, Youtube video, or social media channels—as long as there’s a URL, it can be turned
                      into a QR Code. Some of the popular types of Dynamic QR Codes include Vcard QR Code, Website QR
                      Code, and PDF QR Code.
                    </p>
                    <p>
                      Dynamic QR Codes are a type of QR Code that is editable, trackable, can store a lot of information
                      without taking up too much space, can be quickly scanned, and even change the URL, giving it the
                      flexibility to be used anywhere without having to keep making new ones every time.
                    </p>
                    <p>
                      The 2 biggest advantages are (1) editable as per above and (2) it allows you to monitor and gauge
                      your scanning activity with real time analytics.
                    </p>
                  </Reveal>
                </div>
              </div>
            </div>
            <div className={`agency_featured_item row `} id="landingPages">
              <div className="col-lg-5 pt-lg-5 mt-lg-4">
                <Reveal effect="fadeInLeft">
                  <div className="agency_featured_img text-center">
                    <img src={require("../img/custom-images/landing.png")} alt="" className="img-fluid" />
                  </div>
                </Reveal>
              </div>
              <div className="col-lg-7 ">
                <div className={`agency_featured_content wow fadeInLeft pl-lg-4`} data-wow-delay="0.6s">
                  <Reveal effect="fadeInRight">
                    <h3>Landing Page </h3>
                    <h5 className="mb-3">Engage your audience </h5>
                    <p>
                      Include more ways for your audience to connect and engage with you online. Think of it as a one
                      page website with a link to all of the sites or pages that are important to your campaign. On top
                      of that you can add links to your socials to allow your users to connect the way they like to,
                      some may use Instagram, others Twitter and others love Youtube over Facebook.
                    </p>
                    <p>You can create with confidence.</p>
                    <p>
                      The information on the page as well as all of the links are dynamic and can be edited, replaced
                      and updated at any time with the need to ever change the or reprint your QR code.
                    </p>
                    <p>Run a successful printed marketing campaign using a Landing Page QR code in your print ads.</p>
                    <p>
                      By creating and including a Landing Page QR code in your printed campaign, you will be able to
                      provide more information and more engagement options for your audience.
                    </p>
                    <p>
                      When the QR code is scanned, it gives your target audience valuable additional information about
                      your campaign, but also allows them to choose the point of interest they want, maximizing your
                      marketing campaign’s reach.
                    </p>
                    <div className="pt-4">
                      <div class="embed-responsive embed-responsive-16by9  feature_vid_box">
                        <ReactPlayer
                          width="640"
                          height="360"
                          frameborder="0"
                          title="vimeo-player"
                          class="embed-responsive-item"
                          controls="true"
                          style={{ background: "#000" }}
                          allowfullscreen
                          url="https://player.vimeo.com/video/582799431?transparent=0"
                          volume={0.01}
                        />
                      </div>
                    </div>
                  </Reveal>
                </div>
              </div>
            </div>
            <div className={`agency_featured_item align-items-center row flex-row-reverse `} id="design">
              <div className="col-lg-5 ">
                <div class="embed-responsive embed-responsive-16by9  feature_vid_box">
                  <ReactPlayer
                    width="640"
                    height="360"
                    frameborder="0"
                    title="vimeo-player"
                    class="embed-responsive-item"
                    controls="true"
                    style={{ background: "#000" }}
                    allowfullscreen
                    url="https://player.vimeo.com/video/583781298?transparent=0"
                    volume={0.01}
                  />
                </div>
              </div>
              <div className="col-lg-7 ">
                <div className={`agency_featured_content wow fadeInLeft pr-lg-4`} data-wow-delay="0.6s">
                  <Reveal effect="fadeInRight">
                    <h3>Design</h3>
                    <h5 className="mb-3">Make your QR code come to life </h5>
                    <p>
                      With Zayo QR customization it’s more than just black and white. Brand it your way, add your
                      company logo and colors, use the call-to-action templates to attract more scans. No design skills
                      needed – it is all click and create making it quick and easy to design, save and print.
                    </p>
                  </Reveal>
                </div>
              </div>
            </div>
            <div className={`agency_featured_item align-items-center row `} id="analytics">
              <div className="col-lg-5 ">
                <Reveal effect="fadeInLeft">
                  <div className="agency_featured_img text-right">
                    <img src={require("../img/custom-images/feature_analytics.jpg")} alt="" className="img-fluid" />
                  </div>
                </Reveal>
              </div>
              <div className="col-lg-7 ">
                <div className={`agency_featured_content wow fadeInLeft pl-lg-4`} data-wow-delay="0.6s">
                  <Reveal effect="fadeInRight">
                    <h3>Analytics </h3>
                    <h5 className="mb-3">You can’t manage what you can’t measure</h5>
                    <p>
                      If you’re a marketer, you know how important it is to monitor the behaviour of your audience. It
                      helps you understand them better and improve your subsequent campaigns accordingly.
                    </p>
                    <p>
                      Imagine running a print media campaign over a month, heavily investing in the weekend. After you
                      look at the analytics and see a pattern of Thursday and Friday being the most scanned. You can now
                      adjust your budget and planning to suit where the real traffic is.
                    </p>
                    <p>
                      With Dynamic QR codes you can always track important scanning information and adjust all your
                      campaigns instantly by updating its content. You can track data at the time of the scan, number of
                      scan, location and device type (iPhone/ android).
                    </p>
                  </Reveal>
                </div>
              </div>
            </div>
            <div className={`agency_featured_item align-items-center row flex-row-reverse `} id="targetedMarketing">
              <div className="col-lg-5 ">
                <Reveal effect="fadeInLeft">
                  <div className="agency_featured_img text-right">
                    <img src={require("../img/custom-images/targeted_messaging.jpg")} alt="" className="img-fluid" />
                  </div>
                </Reveal>
              </div>
              <div className="col-lg-7 ">
                <div className={`agency_featured_content wow fadeInLeft pr-lg-4`} data-wow-delay="0.6s">
                  <Reveal effect="fadeInRight">
                    <h3>Targeted Messaging</h3>
                    <h5 className="mb-3">
                      Connecting your offline marketing with your online is now not only simple but necessary
                    </h5>
                    <p>
                      If a QR Code is not a part of your offline marketing strategy yet, you might be missing out. QR
                      Codes are being extensively used for driving traffic, generating customer interest and increasing
                      sales.
                    </p>
                    <p>
                      Turn your links, social links, PDFs, and locations into QR Codes - faster than ever! Create QR
                      Codes for all your marketing campaigns to make it simple for people to connect your offline
                      marketing quickly and effectively with your online presence.
                    </p>
                    <p>
                      If you advertise and put your <Link to="#">www.webaddress.com</Link> for users to type in their
                      phone, you are aware that most people will type your business or product into Google – who in turn
                      happily shows your competition and others in the same view. Bypass that noise! With a QR code they
                      scan and go directly to where YOU want them to go.
                    </p>
                  </Reveal>
                </div>
              </div>
            </div>
            <div className={`agency_featured_item align-items-center row `} id="howToCreate">
              <div className="col-lg-7 pt-lg-5">
                <div class="embed-responsive embed-responsive-16by9 feature_vid_box">
                  {/* <iframe
                      class="embed-responsive-item"
                      title="vimeo-player"
                      src="https://player.vimeo.com/video/582795710?transparent=0"
                      width="640"
                      height="360"
                      frameborder="0"
                      allowfullscreen
                    ></iframe> */}
                  <ReactPlayer
                    width="640"
                    height="360"
                    frameborder="0"
                    title="vimeo-player"
                    class="embed-responsive-item"
                    controls="true"
                    style={{ background: "#000" }}
                    allowfullscreen
                    url="https://player.vimeo.com/video/582795710?transparent=0"
                    volume={0.01}
                  />
                </div>
              </div>
              <div className="col-lg-5 ">
                <div className={`agency_featured_content wow fadeInLeft pl-lg-4`} data-wow-delay="0.6s">
                  <Reveal effect="fadeInRight">
                    <h3>How to Create </h3>
                    <h5 className="mb-3">Create your Zayo QR code in 5 easy steps</h5>
                    <p>
                      <span className="f_500 p-0">Step 1:</span> select Create a QR in Zayo dashboard
                    </p>
                    <p>
                      <span className="f_500 p-0">Step 2:</span> copy and paste your URL link in the fields
                    </p>
                    <p>
                      <span className="f_500 p-0">Step 3:</span> customize your design, personalize your branding
                    </p>
                    <p>
                      <span className="f_500 p-0">Step 4:</span> create your QR code and TEST
                    </p>
                    <p className="mb-0">
                      <span className="f_500 p-0">Step 5:</span> download and you’re ready to print
                    </p>
                  </Reveal>
                </div>
              </div>
            </div>
            {/* <FeaturesitemsCustom
                            rowClass="row flex-row-reverse"
                            aClass="pr-lg-4"
                            fimage="process_1.png"
                            // iImg="icon01.png"
                            ftitle=
                            ftitle2=
                            descriptions={[
                                "If you advertise, promote or print, you need to make it easy for people to connect with your online message.",
                                "Whether it’s your website, a specific page on your website such as a contact form or landing page, a Dropbox link, Youtube video, or social media channels—as long as there’s a URL, it can be turned into a QR Code. Some of the popular types of Dynamic QR Codes include vCard QR Code, website QR Code, and PDF QR Code.",
                                "Dynamic QR Codes are a type of QR Code that is editable, trackable, can store a lot of information without taking too much space, can be quickly scanned, and even change the URL, giving it the flexibility to be used anywhere without having to keep making new ones every time.",
                                "The 2 biggest advantages are (1) editable as per above and (2) it allows you to monitor and gauge your scanning activity with real time analytics. ",
                            ]}
                        /> */}
          </div>
        </div>
      </section>
      <FooterTwo FooterData={FooterData} />
    </Fragment>
  );
};

export default Features;
