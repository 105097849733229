import React , {Component} from 'react';
import AboutWidget from './FooterWidget/AboutWidget';
import SolutionWidget from './FooterWidget/SolutionWidget';
import TeamWidget from './FooterWidget/TeamWidget';
import Reveal from 'react-reveal/Reveal'
import {NavLink} from 'react-router-dom';
class FooterTwo extends Component {
    render(){
        var {fClass} = this.props;
        let FooterData = this.props.FooterData;
        return(
            <footer className={`footer_area footer_area_four f_bg ${fClass}`}>
                <div className="footer_top">
                    <div className="container">
                        <div className="row">
                            {
                                FooterData.CompanyWidget.map(widget=>{
                                    return(
                                        <Reveal effect="fadeInUp" key={widget.id}>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="f_widget company_widget wow fadeInLeft" data-wow-delay="0.2s">
                                                    <NavLink className="f-logo" to="/">
                                                        <img src={require('../../img/custom-images/logo.svg')} width="120px" alt=""/>
                                                    </NavLink>
                                                    {/* <a href="index.html" className="f-logo">
                                                        <img src={require('../../img/custom-images/logo.svg')} width="120px" alt=""/>
                                                    </a> */}
                                                    <div className="widget-wrap">
                                                        <p className="f_400 f_p f_size_15 mb-0 l_height34">
                                                            <span>Email:</span> 
                                                            <a href="mailto:customercare@zayo.com.au" className="f_400">customercare@zayo.com.au</a>
                                                        </p>
                                                        {/* <p className="f_400 f_p f_size_15 mb-0 l_height34"><span>Phone:</span> <a href="tel:948256347968" className="f_400">+948 256 347 968</a></p> */}
                                                    </div>
                                                    {/* <form action="#" className="f_subscribe mailchimp" method="post">
                                                        <input type="text" name="EMAIL" className="form-control memail" placeholder="Email"/>
                                                        <button className="btn btn-submit" type="submit"><i className="ti-arrow-right"></i></button>
                                                    </form> */}
                                                </div>
                                            </div>
                                        </Reveal>
                                    )
                                })
                            }
                            {/* <AboutWidget ftitle="About Us"  FooterData={FooterData}/>
                            <SolutionWidget ftitle="Workflow Solutions" FooterData={FooterData}/>
                            <TeamWidget ftitle="Team Solutions" FooterData={FooterData}/> */}
                        </div>
                    </div>
                </div>
                <div className="footer_bottom">
                    <div className="container">
                        <div className="row align-items-center">
                            {/* <div className="col-lg-4 col-md-5 col-sm-6">
                                <p className="mb-0 f_400">{FooterData.copywrite}</p>
                            </div> */}
                            {/* <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="f_social_icon_two text-left">
                                    {
                                        FooterData.socialIcon.map(item =>{
                                            return(
                                                <a href="/" key={item.id}><i className={item.icon}></i></a>
                                            )
                                        })
                                    }
                                </div>
                            </div> */}
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <ul className="list-unstyled f_menu text-left">
                                    <li><NavLink title="Terms" className="nav-link pl-md-0" to="/Terms">Terms of Use</NavLink></li>
                                    <li><NavLink title="Privacy" className="nav-link" to="/Privacy">Privacy Policy</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
export default FooterTwo;