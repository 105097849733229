import React, { useState } from 'react'

const Activecampaign = () => {
  const [email, setEmail] = useState('')
  const onSubmit = async event => {
    event.preventDefault()
    const data = new FormData(event.target)

    fetch('https://zayo.activehosted.com/proc.php', {
      method: 'POST',
      body: data,
      mode: 'no-cors'
    })
      .then(response => {
        localStorage.setItem('ppemail', email)
        window.location.replace(
          'https://zayo.com.au/admin-panel/business-user-signup'
        )
      })
      .catch(error => console.log('Request failed', error))
  }

  return (
    <div className='active-campaign-control'>
      <form onSubmit={e => onSubmit(e)}>
        <input type='hidden' name='u' value='1' />
        <input type='hidden' name='f' value='1' />
        <input type='hidden' name='s' />
        <input type='hidden' name='c' value='0' />
        <input type='hidden' name='m' value='0' />
        <input type='hidden' name='act' value='sub' />
        <input type='hidden' name='v' value='2' />

        <div className='input-group mb-0'>
          <input
            type='email'
            name='email'
            className='form-control'
            placeholder='Email Address'
            autoComplete='off'
            required
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <div className='input-group-append'>
            <button
              type='submit'
              className='btn_hover agency_banner_btn wow fadeInLeft'
              data-wow-delay='0.5s'
            >
              Try for Free
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Activecampaign
