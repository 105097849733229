import React from 'react'
import { Link } from 'react-router-dom';

const qrUsecaseList = [
    {
        id: 'qruc1',
        title: 'Retail'
    },
    {
        id: 'qruc2',
        title: 'Real Estate',
        link: '/real-estate'
    },
    {
        id: 'qruc3',
        title: 'Flyers & brochures'
    },
    {
        id: 'qruc4',
        title: 'Business cards - VCard'
    },
    {
        id: 'qruc5',
        title: 'Product packaging'
    },
    {
        id: 'qruc6',
        title: 'Posters'
    },
    {
        id: 'qruc7',
        title: 'Labels'
    },
    {
        id: 'qruc8',
        title: 'Magazines'
    },
    {
        id: 'qruc9',
        title: 'Newspaper Ads'
    },
    {
        id: 'qruc10',
        title: 'Displays'
    },
    {
        id: 'qruc11',
        title: 'Hotels & leisure'
    },
    {
        id: 'qruc12',
        title: 'Hospitality'
    },
    {
        id: 'qruc13',
        title: 'Galleries & Museums'
    },
    {
        id: 'qruc14',
        title: 'Store windows'
    },
    {
        id: 'qruc15',
        title: 'Car sales'
    },
]

const QrUseCase = () => {
    return (
        <section className="qr_usecase_section bg_color">
            <div className="container custom_container">
                <h2
                    className="f_size_30 f_600 t_color3 l_height40 text-center mb-30 wow fadeInUp"
                    data-wow-delay="0.2s"
                >
                    Use cases for QR codes in <br />
                     your business or industry
                </h2>
                <div className="qr_usecase_container">
                    {qrUsecaseList.map((item) => (
                        <Link to={item.link} className="qr_usecase_box" key={item.id}>
                          <h5>{item.title}</h5>
                        </Link>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default QrUseCase
