import React from 'react'
import Reveal from 'react-reveal/Reveal';
import process4Img from '../img/home4/process_4.png'
import { Film, Grid, PieChart, Printer, Smartphone, Target } from 'react-feather';
import { HashLink } from 'react-router-hash-link';
const qrFeaturesData = [
    {
        id:"qr1",
        title: 'Dynamic QR codes',
        text: 'Edit your QR content at anytime – even after printing',
        icon: <Grid/>,
        link: '/Features#dynamicQrCode'
    },
    {
        id:"qr2",
        title: 'Design your way',
        text: 'Personalize your QR with brand colors, logo and designs',
        icon:  <Film/>,
        link:'/Features#design'
    },
    {
        id:"qr3",
        title: 'Targeted messaging',
        text: 'Share links, email, text, video content & PDF linked to your QR',
        icon: <Target/>,
        link: '/Features#targetedMarketing'
    },
    {
        id:"qr4",
        title: 'Mobile landing pages',
        text: 'Create customized landing pages linked to multiple sites',
        icon: <Smartphone/>,
        link: '/Features#landingPages'
    },
    {
        id:"qr5",
        title: 'Real time analytics',
        text: 'Track the numbers of your scans by date and device',
        icon: <PieChart/>,
        link: '/Features#analytics'
    },
    {
        id:"qr6",
        title: 'Hi-res printing',
        text: 'Export your QR code in PNG, PDF and SVG',
        icon: <Printer/>,
        link:'/Printing-Guide'
    },
]

const QrFeatures = () => {
    return (
        <section className="qr_features_section bg_color">
            <div className="container custom_container">
                <div className="row align-items-center">
                    <div className="col-lg-8 qr_features_main_col ">
                        <Reveal effect="fadeInLeft">
                            <h2 className="f_size_30 f_600 t_color3 l_height40 text-center mb-30">QR FEATURES</h2>
                            <div className="row qr_feature_row">
                                {qrFeaturesData.map((item) => (
                                    <div className="col-sm-6 col-lg-4 qr_feature_col" key={item.id}>
                                        <div className="qr_features_box">
                                            {item.icon}
                                            <h5 className="f_size_16 mt-3">{item.title}</h5>
                                            <p className="f_size_13">{item.text}</p>
                                            <HashLink to={item.link} className="f_size_12 learn_btn_two">
                                            More <i className="arrow_right"></i>
                                            </HashLink>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-lg-4">
                        <Reveal effect="fadeInRight">
                            <div className="agency_featured_img text-right pl-lg-5">
                                <img src={require("../img/custom-images/home_qr_features.jpg")} alt="" />
                            </div>
                        </Reveal>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default QrFeatures
