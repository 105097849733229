import React, { Fragment } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import CustomNavbar from "../../components/CustomNavbar";
import FooterTwo from "../../components/Footer/FooterTwo";
import FooterData from "../../components/Footer/FooterData";
import { Reveal } from "react-reveal";

const RealEstate = () => {
  return (
    <Fragment>
      <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto" />
      <Breadcrumb
        breadcrumbClass="breadcrumb_area"
        imgName="breadcrumb/banner_bg.png"
        Ptitle="Use Cases for Real Estate QR codes"
      />
      <section className="printing_guide_section sec_pad">
        <div className="container">
          {/* <h5 className="f_500 mb-3">Getting it right before you go to print</h5> */}
          <p className=" mb-4">
            <span className="f_500 p-0">Zayo QR</span> enables you as a real estate agent or company to connect
            potential customers with relevant information of your real estate on the spot using QR Codes. Assign your
            online link to a QR Code and print the code in your newspaper ads, or on your handouts, flyers or on posters
            or magazines where you advertise.
          </p>

          <p className="mb_70">
            A potential customer that scans the QR Code immediately gets all relevant information about your offer on
            their Smartphone. The best thing for your marketing department is now you know exactly how many times
            someone has used the QR to visit your designated site, link, video or URL.
          </p>
          <h5 className="f_500 mb-30">Use QR Codes on your -</h5>

          <div className="row">
            <div className="col-lg-6 d-flex align-content-stretch ">
              <div className="custom-bordered-box">
                <h4 className=" mb-3"> Business cards </h4>
                <p className="mb_40">
                  The simple business card – by adding a QR code on your business card is an effective way for your
                  clients to easily add your information to their devices. This means they can add your info in to their
                  mobile device without the hassle of typing it in – or worse - losing your card. All they have to do is
                  scan it and save.
                </p>
                <h4 className=" mb-3"> Flyers and Brochures </h4>
                <p className="mb_40">
                  By combing digital with your traditional marketing you can now measure detailed information on your
                  audiences engagement. By adding a well placed QR code with a strong call to action you can now target
                  your online platforms in one easy scan helping you optimise your marketing strategy. Using a QR
                  Landing Page gives you scope to give the user options to view a specific property, direct them your
                  social media pages, help promote your agency and gain a connected client.
                </p>
                <h4 className=" mb-3"> Newspaper Advertising </h4>
                <p>
                  By integrating dynamic QR codes in your advertising you allow for an increase in your prospects
                  experience by now engaging them with an interactive campaign. Imagine a new home buyer or investor
                  sitting at the local café reading the Saturday morning paper in the Real Estate section and they see a
                  house they like. Now instead of asking them to type the property address or call you, they can simply
                  scan the code and go straight to your site, video or socials (a) without having to type anything in
                  (b) bypassing our old friend Google (c) saving them time and effort. All the while giving you
                  analytics on scans helping you manage your advertising budget and effectiveness.
                </p>
              </div>
            </div>
            <div className="col-lg-6 d-flex align-content-stretch  mt-5 mt-lg-0">
              <div className="custom-bordered-box w-100">
                <img
                  className="img-fluid w-100"
                  src={require("../../img/custom-images/usecase-realestate-img1.jpg")}
                  alt="real estate"
                />
              </div>
            </div>
          </div>
          {/* real estate bordered img container */}
          <div className="re-bordered-img-container sec_pad pb-0 px-3 mb_40">
            <div className="row">
              <div className="col-6 re-bordered-col">
                <div className="re-img-box">
                  <img
                    className="img-fluid"
                    src={require("../../img/custom-images/usecase-realestate-img2.jpg")}
                    alt="real estate"
                  />
                </div>
              </div>
              <div className="col-6 re-bordered-col">
                <div className="re-img-box">
                  <img
                    className="img-fluid"
                    src={require("../../img/custom-images/usecase-realestate-img3.jpg")}
                    alt="real estate"
                  />
                </div>
              </div>
              <div className="col-6 re-bordered-col">
                <div className="re-img-box">
                  <img
                    className="img-fluid"
                    src={require("../../img/custom-images/usecase-realestate-img4.jpg")}
                    alt="real estate"
                  />
                </div>
              </div>
              <div className="col-6 re-bordered-col">
                <div className="re-img-box">
                  <img
                    className="img-fluid"
                    src={require("../../img/custom-images/usecase-realestate-img5.jpg")}
                    alt="real estate"
                  />
                </div>
              </div>
            </div>
          </div>
          <h4 className=" mb-3"> Window Displays </h4>
          <p className="mb_40">
            Bring your window display to life by adding a QR code to your collateral. This will allow you to always be
            available to your potential buyers especially after hours and on weekends. Use these for deeper engagement
            with busy people directing them to the video you have on the property or get them to arrange an appraisal on
            their property while they are looking for their next home. Make connecting and engaging with the property of
            their choice as well as your office, quickly and effectively, giving your prospects an amazing experience
            along the way.
          </p>
          <h4 className=" mb-3"> Virtual Tours via QR codes </h4>
          <p className="mb_40">
            Industry statistics are as high as 85% when it comes to Buyers and Sellers wanting to work with agents who
            use video to market their properties. YouTube makes it easy, Facebook, Insta and now Tik Tok are going nuts
            with video content and consumers are accustomed to using these platforms more than they are going to your
            website for everything including real estate. They are a great way for increasing engagement but more
            importantly video’s help with your SEO (among other things) as well.
          </p>
          <h4 className=" mb-3"> QR Code Analytics </h4>
          <p>
            The sales rep at the Daily Planet will tell you they have over 160,000 papers printed every month and to
            reach your local market they all read the local Real Estate section and you must be in it
          </p>
          <p className="mb_70">
          <span className="f_500 p-0">Quantify it!</span> they can’t really – (the number of people actually reading
            your ad not the amount of prints).{" "}
            <span className="text-underline">But with a Dynamic QR code you can</span>. This allows you to see how many
            times an ad was scanned, on what day, how many unique scans versus total scans, giving you the data to
            quantify and justify your advertising dollar spend.
          </p>

          <div className="row align-items-center mb_40 ">
            <div className="col-lg-4">
              <div className="agency_featured_img text-center pr-lg-5">
                <img
                  src={require("../../img/custom-images/usecase-realestate-img6.jpg")}
                  alt="real estate"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-8 mt-5 mt-lg-0">
              <h4>Connect your Social Media marketing with QR codes</h4>
              <p>
                It is <span className="f_500 p-0">imperative</span> to build a strong Social Media presence for Real
                Estate marketers worldwide. As social media platforms rank as the top go to sites in 2021 this is the
                ideal opportunity to connect with and gain more clients online. As it is within the industry you have
                marketing collateral you must use in printed form, <span className="f_500 p-0">BUT</span>, the key is
                connecting this with your social media. “But how?” you ask (loud trumpets and drum roll ……..) enter the
                humble QR code.
              </p>
              <p>
                Marketing experts are now using social media QR codes to direct clients and prospects to their social
                platforms. By using a QR code their customers don’t have to bother searching for their accounts or
                profiles. In a single scan they can now explore and engage with your social media accounts instantly!
              </p>
            </div>
          </div>
          <p>
            Dynamic QR codes offer more versatility and the information can be changed or edited after the code has been
            created. This is beneficial for when you print a code on your marketing collateral and need to change the
            content or even page to which the QR code is linked. So even though you have a brochure printed displaying a
            QR code, the information contained can be updated and edited without the need to reprint anything.
          </p>
          <p className="mb_40">
            What makes dynamic codes even better is that they can be tracked, so you can see live data through an
            analytics standpoint. Zayo QR codes for Real Estate provides the more versatile and user friendly dynamic QR
            codes for your success.
          </p>
          <h4 className="mb-3"> Landing Pages</h4>
          <p>
            If you are serious about giving your buyers and sellers the best experience, using a QR code Landing Page is
            going to make you look great. You can direct users to multiple call to actions (CTA) on the one page
            including links to all of your social media.
          </p>
          <p>
            Imagine a property having it’s own landing page QR code allowing the scanner to view not only the link to
            the property on your website but also a link to choose to watch the video presentation, or to apply to have
            an appraisal, speak to an agent. If they prefer Instagram over Facebook – they choose, that’s the magic with
            a landing page.
          </p>
          <p className="mb_40">Think of it as a bio page for the property, the agency or the agent.</p>
          <h4 className="mb-3"> Single use QR codes </h4>
          <p className="mb_40">
            When creating a flyer, brochure or doing an advert for a particular property you can assign a single QR code
            attached to the URL of the property you have online. If you want to show a walk-through video presentation
            or go straight to your Facebook link showing the property, this is the best way to go for a more targeted
            specific approach.
          </p>
          <h4 className="mb-3"> Team Collaboration </h4>
          <p className="mb_70">
            With Zayo business solutions you can invite team members to create, manage and collaborate on the QR creator
            platform. Giving you the advantage of allowing your marketing department or sales team to be responsible for
            the creation and management of their own amazing QR code solutions.
          </p>
          <div className="col-lg-9 mx-auto px-0">
            <img
              src={require("../../img/custom-images/usecase-realestate-img7.jpg")}
              alt="easy steps"
              className="img-fluid"
            />
          </div>
        </div>
      </section>
      <FooterTwo FooterData={FooterData} />
    </Fragment>
  );
};

export default RealEstate;
