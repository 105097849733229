import React, {Component} from 'react';
import Featuresitems from './Featuresitems';

class Features extends Component{
    render(){
        var {aClass} =this.props;
        return(
            <section className={`agency_featured_area bg_color ${aClass}`}>
                <div className="container">
                    <h2 className="f_size_30 f_600 t_color3 l_height40 text-center wow fadeInUp" data-wow-delay="0.3s">How can your Marketing improve <br/>with Zayo QR Code solutions?</h2>
                    <div className="features_info features_info1">
                        <img className="dot_img" src={require ('../../img/home4/dot.png')} alt=""/>
                        <Featuresitems rowClass="row flex-row-reverse agency_featured_item_one" aClass="pr_70 pl_70" fimage="points-side-img.png" iImg="icon01.png" ftitle="Create QR Codes for all your marketing campaigns" descriptions="Turn your web links, social links, promotions and sales into QR Codes - faster than ever! If your business has a printed advertisement (on anything, anywhere) with a QR code you just gave your audience the fastest and simplest way to engage with your marketing by taking them directly to your online or offline platforms."/>
                        {/* <Featuresitems rowClass="row agency_featured_item_two" aClass="pl_100" fimage="work2.png" iImg="icon02.png" ftitle="Wuth instant data to keep everyone in the know" descriptions="Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy cras tickety-boo squiffy. Starkers dropped a clanger lurgy is cack excuse my French what a plonker blower.!"/>
                        <Featuresitems rowClass="row flex-row-reverse" aClass="pr_70 pl_70" fimage="work3.png" iImg="icon3.png" ftitle="With efficiency to unlock more opportunities" 
                        descriptions="Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy cras tickety-boo squiffy. Starkers dropped a clanger lurgy is cack excuse my French what a plonker blower.!"/> */}
                        {/* <div className="dot middle_dot"><span className="dot1"></span><span className="dot2"></span></div> */}
                        
                        <Featuresitems rowClass="row flex-row-reverse agency_featured_item_two" aClass="pr_70 pl_70" fimage="points-side-img2.png" iImg="icon02.png" ftitle="Make Customer Engagement easy, quick and useful" descriptions="Print media such as newspapers, magazines, catalogues, brochures and leaflets have come of age utilizing Qr Codes, allowing your potential clients to interact off the page immediately. Giving you the power to direct them exactly where you want them to engage online. Best of all you can track and monitor every view!"/>
                        <img className="dot_img" src={require ('../../img/home4/dot.png')} alt=""/>
                        <img className="dot_img" src={require ('../../img/home4/dot.png')} alt=""/>
                        <Featuresitems rowClass="row flex-row-reverse agency_featured_item_three" aClass="pr_70 pl_70" fimage="points-side-img3.png" iImg="icon3.png" ftitle="Tell your story to unlock more opportunities" descriptions="By using a QR code on your packaging, wine bottles or on what ever you use for your product, you allow your story to come to life for a better customer experience. It helps build brand trust and you can show them your product story or details all while they are deciding to buy."/>
                        <img className="dot_img 3" src={require ('../../img/home4/dot.png')} alt=""/>
                    </div>
                </div>
            </section>
        )
    }
}
export default Features;