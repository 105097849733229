import React, { Fragment } from 'react'
import Breadcrumb from '../components/Breadcrumb'
import CustomNavbar from '../components/CustomNavbar'
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import { Reveal } from 'react-reveal';

const PrintingGuide = () => {
    return (
        <Fragment>
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto" />
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Printing Guide " />
            <section className="printing_guide_section sec_pad">
                <div className="container">
                    <h5 className="f_500 mb-3">Getting it right before you go to print</h5>
                    <p className=" mb_40">
                        Size and format matter! There are a few technical elements to keep in mind for design and sizing
                        so that your QR Codes continue to work effectively. Not to mention that unprofessional designs
                        can affect your company's reputation. It’s worth double-checking graphic design aspects, because
                        QR Code scanning problems from bad graphics could result in lost customers and failed marketing
                        campaign.{" "}
                    </p>

                    <h5 className="f_500 mb-3">Step 1: Size</h5>
                    <p className=" mb_40">
                        In general the minimum size of a QR code for print should be 20mm x 20mm. Even if most modern
                        smartphones may read a QR Code with a length less than 5mm this will not be the case for 100% of
                        all phones out there.
                    </p>

                    <h5 className="f_500 mb-3">Step 2: The Border or Quiet Zone is vitally important</h5>
                    <p className=" mb_40">
                        Leave enough quiet zone around the QR Code which should be no less than about 4 units. If the
                        distance between the pixels of the QR Code and the adjacent graphic elements is too small, the
                        QR code cannot be decoded.{" "}
                    </p>

                    <h5 className="f_500 mb-3">Step 3: Provide enough contrast</h5>
                    <p className=" mb_40">
                        The colour of the QR code should be different enough from the background colour, so that the
                        camera is able to decode the QR Code. The QR code on the right-side blends too much into the
                        background and is hard to be decoded by a QR Code Reader
                    </p>

                    <h5 className="f_500 mb-3">Step 4: Call to Action</h5>
                    <p className=" mb-3">
                        Why would a customer scan your QR Code? Tell them what to expect when they scan. Even a simple
                        “Scan Me!” has proven to engage a lot more users than Codes without any call to action. Your
                        Call To Action (CTA) gives the reader a reason to get them intrigued and motivated to scan your
                        Codes. Many brands tend to forget adding a “call for action” in their QR codes, in turn only a
                        few people are scanning their QR codes.{" "}
                    </p>
                    <p className=" mb_40">
                        You can create a short yet compelling call to action like scan me, scan and win, scan and view
                        more into your QR code to give a definitive overview for the people who will be scanning them.
                    </p>

                    <h5 className="f_500 mb-3">Step 5: Positioning </h5>
                    <p className=" mb_40">
                        To improve your marketing stance, being conscious about the positioning of your QR code! is a
                        necessity that every marketer needs to learn. To make the most of your QR code you must also place
                        the QR code where customers can see and scan it easily. QR codes that are placed too high or too
                        low where the customers will have to crouch to scan will discourage them from scanning the QR
                        code{" "}
                    </p>

                    <h5 className="f_500 mb-3">Step 6: Test Test Test </h5>
                    <p className=" mb_70">
                        Once you have designed your QR Code, the final step is to test your QR Code. At the very least,
                        use various scanning apps to ensure that your Code is readable. For best QA test, test using
                        multiple devices with different operating systems (iOS, Android, Windows). Always request a
                        proof from your printer to test scan before printing a large volume of your QR Codes for
                        distribution. Then your campaign is ready to go!{" "}
                    </p>

                    <h3 className="f_size_30 mb-4">Which file to download? </h3>
                    <h5 className="f_500 mb-3">SVG file:</h5>
                    <p className=" mb-3">
                        SVG stands for  “Scalable Vector Graphics” .
                        <a
                            href="https://techterms.com/definition/vectorgraphic"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Vector graphics
                        </a>{" "}
                        are an image file format that, as opposed to JPEG, for example, isn’t made up of pixels.
                        Instead, it uses paths, which is a particular shape used to create an image. They work well for
                        both simplistic drawings and complex diagrams.
                    </p>
                    <p className=" mb_40">
                        SVG files are useful when you need to scale your QR Code to a large size. As mentioned above,
                        vector graphics don’t use pixels like other images. The issue with pixel-based images is that if
                        you increase their size, their quality is lessened. Due to this path structure, SVG files don’t
                        have this problem. As a result, they are ideal for web-based images, icons, illustrations, and
                        larger QR Codes.
                    </p>

                    <h5 className="f_500 mb-3">PNG file: </h5>
                    <p className=" mb-3">
                        PNG stands for “Portable Network Graphic” and is a file that uses compression to match bits of
                        data from the image to shortcodes stored within the file itself. This makes it a smaller file,
                        yet still has a high quality. PNG has “
                        <a
                            href="https://www.digitaltrends.com/photography/jpeg-vs-png/"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            lossless compression
                        </a>
                        ”, meaning that quality isn't lost each time the image is opened and saved. They also handle
                        colour contrast well for colour-rich images.
                    </p>
                    <p className=" mb_40">
                        PNG files are often the default for screenshot images. While they are larger files than JPGs,
                        PNG files work well for web graphics. So when you use this type of file for your QR Code, it’s
                        more suitable for placement on your website or social media posts.
                    </p>

                    <h5 className="f_500 mb-3">PDF file: </h5>
                    <p className=" mb_70">
                        PDF files are more useful for complete documents rather than singular images. They can contain
                        any elements that any document might include, such as text, images, hyperlinks, buttons, forms,
                        and more. While it’s not likely that you will use QR Codes directly as PDF files, you can still
                        download them as PDF should you wish to.
                    </p>

                    <h3 className="f_size_30 mb-4">Enhance your marketing with QR codes </h3>
                    <p className=" mb_50">
                        The uptake of QR Codes in business marketing, to enhance marketing and sales capabilities{" "}
                        <span className="f_500 p-0">
                            by bringing their offline to life by instantly connecting to their online presence
                        </span>{" "}
                        with a simple but effective QR code, has been catapulted by 2 things recently. The first is our
                        smartphones’ capability to scan a QR code by only using your camera (no more app to download)
                        and the second is, due to the world-wide pandemic, just about everyone is now accustomed to
                        scanning QR codes. The market is huge and the possibilities are endless.
                    </p>

                    <div className="row">
                        <div className="col-lg-6 d-flex align-content-stretch text-center">
                            <Reveal effect="fadeInLeft">
                                <div className="print_way_container">
                                    <h5 className="f_500 mb-3">The old way </h5>
                                    <p className="">
                                        Using print media doesn’t allow you to monitor your engagement levels. An
                                        advertisement that requires the reader to engage this way has already limited
                                        the chance of any interaction.{" "}
                                    </p>
                                    <img
                                        className="img-fluid"
                                        src={require("../img/custom-images/print_old.png")}
                                        alt=""
                                    />
                                </div>
                            </Reveal>
                        </div>
                        <div className="col-lg-6 d-flex align-content-stretch text-center wow fadeInLeft mt-5 mt-lg-0">
                            <Reveal effect="fadeInRight">
                                <div className="print_way_container">
                                    <h5 className="f_500 mb-3">The better way </h5>
                                    <p className="">
                                        Including Zayo QR to your print media allows users to engage immediately with
                                        your digital marketing while giving you instant visibility on your marketing
                                        media{" "}
                                    </p>
                                    <img
                                        className="img-fluid"
                                        src={require("../img/custom-images/print_better.png")}
                                        alt=""
                                    />
                                    <h5 className="mt-3 f_size_20">Take them where you want them to go</h5>
                                    <p className="">
                                        A QR Code on your printed medium is the best way to drive traffic straight to
                                        your website, promotion or sale. Making it quick and simple for your intended
                                        market to simply go where you want them to.
                                    </p>
                                </div>
                            </Reveal>
                        </div>
                    </div>
                </div>
            </section>
            <FooterTwo FooterData={FooterData} />
        </Fragment>
    );
}

export default PrintingGuide
