import React from 'react';

const FaqSection =()=>{
    return(
        <section className="faq_area bg_color sec_pad">
            <div className="container">
                        <div className="tab-content faq_content" id="myTabContent">
                            <div className="tab-pane fade show active" id="purchas" role="tabpanel" aria-labelledby="purchas-tab">
                                <div id="accordion">
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link pt-0" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                What is a QR Code?<i className="ti-plus"></i><i className="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>

                                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                            <div className="card-body">
                                            A QR Code is a 2D barcode that is encoded with information and can be decoded using a QR Code scanning app on a smartphone. Most smartphones are now equipped with a QR Code scanning feature or app and hence, QR Codes are now popularly used in marketing, payments, and authentication use cases. Typically, a QR Code image—encoded with a website link—is added to promotional material to engage target customers.    </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingTwo">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                What is a QR Code Generator?<i className="ti-plus"></i><i className="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                            <div className="card-body">
                                            A QR Code Generator is an online software tool that allows you to create QR Codes. Advanced QR Code generators such as Zayo QR also allow you to design, manage, and track QR Codes for marketing and operational use cases.
                                          </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingThree">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                Free versus paid QR code services?<i className="ti-plus"></i><i className="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                            <div className="card-body">
                                            I can get a free QR made online why do I need to buy a service? You can create a free QR code from any google browser, free online QR platforms and even social media platforms such as Instagram which will allow you to generate free QR codes. Most free options are static QR Codes. Paid services are more advanced like the Zayo QR platform including better business solutions such as landing pages, analytics, multiple users (great for collaboration), larger storage capacity and the like.
                                          </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingfour">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                                                What is the best format for the logo of the QR code?<i className="ti-plus"></i><i className="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapsefour" className="collapse" aria-labelledby="headingfour" data-parent="#accordion">
                                            <div className="card-body">
                                            You can add a logo to your QR code, however it is important that your logo is in a square format otherwise it might be stretched. It is also important to note whether you upload your logo in JPEG or PNG format. It is recommended to have a logo around 500KB to 1 MB.
                                          </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingfive">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
                                                What is a Vcard?<i className="ti-plus"></i><i className="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapsefive" className="collapse" aria-labelledby="headingfive" data-parent="#accordion">
                                            <div className="card-body">
                                            A Vcard is a QR code that people place on their business cards, so when people scan they have in one click all your information saved to their phone. You can also share a Vcard to your email and edit the data of your Vcard using a Dynamic QR code.
                                         </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingSix">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                Do you offer a guarantee?<i className="ti-plus"></i><i className="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                                            <div className="card-body">
                                            Yes! Zayo offers a full money back guarantee, no questions asked up to 30 days after purchase.
                                         </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingSeven">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                Do you store my credit card information?<i className="ti-plus"></i><i className="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
                                            <div className="card-body">
                                            No. we use Stripe checkout as our payment platform and no credit card or financial information is stored.
                                       </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingEight">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                                My QR code is not working, what can I do?<i className="ti-plus"></i><i className="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseEight" className="collapse" aria-labelledby="headingEight" data-parent="#accordion">
                                            <div className="card-body">
                                            There are many reasons why a QR code is not working correctly. First check your entered data. Sometimes there are little typos in your URL that break your QR code. Make sure that there is enough contrast between the background and foreground of the QR code. The foreground should always be darker than the background.
                                       </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingNine">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                                How do I make a QR code in bulk?<i className="ti-plus"></i><i className="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseNine" className="collapse" aria-labelledby="headingNine" data-parent="#accordion">
                                            <div className="card-body">
                                            You can use our CSV template to find the page to create a bulk QR code or secondly input how many QR codes you want to generate using the same URL but having different unique QR codes, this allows you to have unique QR codes so you can measure who scans. A bulk QR code is useful if you need to have unique QR codes or need to make QR codes of many different URLs.
                                       </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
    </div>
            </div>
        </section>
    )
}
export default FaqSection;